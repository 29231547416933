<template>
  <div
    class="notice-container"
  >
    <div class="notice-content">
      <div class="d-flex align-items-center mb-1">
        <b-img
          :src="require('@/assets/images/pages/overview/ic-warning.svg')"
          alt="logo"
        />
        <h4 class="text-title">
          {{ $t('home.adsBlockedNotice.titleNotice') }}
        </h4>
      </div>
      <div>
        <p class="m-0">
          {{ $t('home.adsBlockedNotice.textDear') }}
          <a
            class="text-blue underline"
            target="_blank"
            href="https://ecomdycom.larksuite.com/docx/doxuss0uZGKoWbc9qTDxMpfbQkT"
          >
            {{ $t('home.adsBlockedNotice.textReadMore') }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>

<style lang="scss" scoped>
.notice-container{
  margin: 24px 0;

  .notice-content{
    width: 100%;

    background-color: #E11C4E1A;
    border-radius: 20px;

    padding: 16px;
    margin: 0 auto;

    .text-title{
      margin:0 0 0 15px;

      font-size: 22px;
    }
  }
}
</style>
