<template>
  <b-modal
    ref="notification-gif-code-modal"
    id="notification-gift-child"
    class="modal-gift-box"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
  >
    <div class="d-flex justify-content-end top-modal px-1 pt-1">
      <div
        class="btn-hide cursor-pointer"
        @click="hideModal"
      >
        <feather-icon
          style="line-height: 0"
          icon="XIcon"
          size="16"
        />
      </div>
    </div>

    <div class="pl-1 pr-1 pb-1 mt-1 d-flex flex-column align-items-center">
      <div class="img-gift-box">
        <b-img
          :src="require('@/assets/images/pages/overview/gift-box-explode.gif')"
          alt="gift-box"
          class="img"
        />
      </div>
      <div class="content-gift">
        <h1 class="title-gift-box">
          {{ $t('profile.giftCodeTab.textExcitingNews') }}
        </h1>
        <p>{{ $t('profile.giftCodeTab.textYouHaveAnAvailableGift') }}</p>
      </div>
      <div class="btn-redeem">
        <b-button
          variant="primary"
          @click="handleGoToGiftCode"
        >
          {{ $t('profile.giftCodeTab.btnRedeemNow') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable camelcase */

import {
  BModal,
  BImg,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BImg,
    BButton,
  },

  methods: {
    hideModal() {
      this.$emit('hide-modal-confirm')
      this.$refs['notification-gif-code-modal'].hide()
    },

    showModal() {
      this.$refs['notification-gif-code-modal'].show()
    },

    handleGoToGiftCode() {
      this.$router.push('/profile?tab=gift-code')
      this.$emit('hide-modal-confirm')
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-hide{
  background-color: rgba(204, 204, 204, 0.5);
  padding: 6px;
  border-radius: 50%;
  line-height: 0;
}

.img-gift-box{
  width: max-content;

  img{
    width: 240px;
  }
}

.content-gift{
  text-align: center;
}
</style>
