<template>
  <swiper
    class="swiper-navigations bg-white border-content event-slide"
    :options="swiperOptions"
  >
    <swiper-slide
      v-for="(slide, index) in swiperData"
      :key="index"
    >
      <b-img
        id="banner-small"
        :src="slide.img"
        class="img-detail w-100 cursor-pointer"
        fluid
        @click="goTo(slide.detailLink)"
      />
      <div class="px-1 pt-1 pb-3">
        <p
          class="slide-title font-weight-bold cursor-pointer"
          @click="goTo(slide.detailLink)"
        >
          {{ slide.title }}
        </p>
      </div>
    </swiper-slide>

    <div
      v-if="hasMultiSlide"
      slot="button-prev"
      class="swiper-button-prev"
    >
      <feather-icon
        icon="ChevronLeftIcon"
        size="20"
      />
    </div>
    <div
      v-if="hasMultiSlide"
      slot="button-next"
      class="swiper-button-next"
    >
      <feather-icon
        icon="ChevronRightIcon"
        size="20"
      />
    </div>

    <div
      v-if="hasMultiSlide"
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },

  data() {
    return {
      /* eslint-disable global-require */
      swiperData: [
        {
          img: require('@/assets/images/pages/overview/banner-left.jpg'),
          //   title: "Saving more with Ecomdy's new fee schedule",
          detailLink: 'https://blog.ecomdymedia.com/blog/increase-your-income-with-ecomdy-media-affiliate-program',
        },
      ],

      /* eslint-disable global-require */
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        allowTouchMove: this.hasMultiSlide,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },

  computed: {
    hasMultiSlide() {
      return this.swiperData?.length > 1
    },
  },

  methods: {
    goTo(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.event-slide {
  display: block;

  @media (max-width: 767px) {
    display: none;
  }
}

.border-content {
  width: 100%;
  border-radius: var(--border-radius-base);
}

.btn-promote {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  padding: 8px 16px;
  font-size: 14px;
}
</style>
<style lang="scss">
.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #16213e;
  opacity: 0.2;
  display: inline-block;
  margin: 0 3px;
  cursor: pointer;
  transition: opacity 0.4s ease-in-out,width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.swiper-pagination-bullet-active {
  width: 22px;
  opacity: 1;
}

.swiper-button-prev:after, .swiper-button-next:after {
  display: none;
}

.swiper-button-prev, .swiper-button-next {
  top: 28%;
  border-radius: 50%;
  width: 44px;
  background-color: rgba(31,45,61,.11);
  opacity: 0.2;
  color: #fff;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 767px) {
    top: 28%;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    top: 24%;
  }

  .slide-title {
    font-size: 20px;

    @media (min-width: 768px) and (max-width: 992px) {
      font-size: 16px;
    }
  }
}

.swiper-slide-active {
  width: 100% !important;
}

.link-detail{
  overflow-wrap: break-word;
}
</style>
