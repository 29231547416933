<template>
  <div class="bg-partnero">
    <div class="btn-join-partnero">
      <div class="content-background">
        <p class="txt-refer-clients">
          Enjoy our
        </p>
        <h1 class="title-ecomdy-affiliate">
          NEW <span class="text-blue font-weight-600">AFFILIATE</span>
        </h1>
        <h1 class="title-ecomdy-affiliate">
          PROGRAM
        </h1>
        <p class="txt-refer-clients m-0">
          Earn <span class="text-blue font-weight-600">30%</span> commission
        </p>
      </div>
      <div class="btn-controller">
        <btn-loading
          class="btn-login"
          variant-convert="btn-submit"
          @click.prevent="handleJoinPartneroProgram"
        >
          {{ $t('referral.tabAffiliateTerms.btnJoinNow') }}
        </btn-loading>
      </div>
    </div>
  </div>
</template>

<script>
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BtnLoading,
  },

  methods: {
    handleJoinPartneroProgram() {
      this.$router.push('/affiliate-program')
    },
  },
}
</script>

<style scoped lang="scss">
.bg-partnero {
  height: 270px;
  width: 100%;

  background-image: url(../../../assets/images/pages/overview/banner-affiliate-program.png);
  border-radius: var(--border-radius-2xl);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .btn-join-partnero {
    width: max-content;
    position: absolute;
    bottom: 30px;
    right: 60px;
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: end;

    .content-background {

      .title-ecomdy-affiliate {
        margin-bottom: .5rem;
        font-size: 35px;
        line-height: 1;
      }

      .txt-refer-clients {
        font-size: 25px;
        margin-bottom: .5rem;
        line-height: 1;
      }
    }

    .btn-controller {
      display: flex;
      align-items: center;
      margin-top: 25px;

      .btn-join-now {
        padding: 12px 24px;
        border-radius: var(--btn-border-radius-base);
        font-size: 14px;
        border: 1px solid #FFF !important;
        background: transparent !important;
      }

      .btn-login {
        padding: 12px 24px;
        height: 42px;
        //width: 122px;
      }

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: start;
      }
    }

    @media (max-width: 575px) {
      right: 55px;
      bottom: 20px;
    }

    @media (max-width: 375px) {
      right: 30px;
      bottom: 15px;
    }
  }

  @media (max-width: 575px) {
    height: 250px;
  }
}
</style>
