<template>
  <div class="bg-white">
    <div class="form-action">
      <div class="filter-desktop list-ads-account">
        <b-row class="align-items-center">
          <b-col md="12">
            <div class="d-flex justify-content-end mt-1">
              <btn-loading
                id="trigger-new-account"
                class="ml-1 font-14 d-flex align-items-center btn-new-account"
                variant-convert="btn-submit"
                @click="handleAddNewAccount"
              >
                {{ $t("home.tableListAdsAccount.btnNewAccount") }}
              </btn-loading>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- table -->
    <vue-good-table
      class="vgt-no-border"
      mode="remote"
      :columns="columns"
      :rows="accounts"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalAccount > 0,
      }"
      :sort-options="{
        enabled: totalAccount > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === '_id'"
          class="d-flex align-items-center"
        >
          <b-img
            class="ads-avt-img"
            :src="adsAccountImg"
          />
          <div class="d-flex flex-column">
            <b-link
              class="underline text-primary font-weight-600"
              style="margin-bottom: 5px"
              :href="`https://ads.tiktok.com/i18n/dashboard?aadvid=${props.row._id}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              {{ props.row.name }}
            </b-link>
            <span class="text-placeholder">ID: {{ props.row._id }}</span>
          </div>
        </div>

        <!-- Column: Cost -->
        <span v-else-if="props.column.field === 'report.cost'">
          <span v-if="props.row.report && props.row.report.cost">
            ${{ amountFormat(props.row.report.cost) }}
          </span>
          <span v-else>N/A</span>
        </span>

        <!-- Column: cpc -->
        <span v-else-if="props.column.field === 'report.cpc'">
          <span v-if="props.row.report && props.row.report.cpc">
            ${{ amountFormat(props.row.report.cpc) }}
          </span>
          <span v-else>N/A</span>
        </span>

        <!-- Column: timezone -->
        <div
          v-else-if="props.column.field === 'timezone'"
          class="d-flex align-items-center"
        >
          <span
            v-if="props.row.displayTimezone"
            class="text-nowrap text-capitalize"
          >
            {{ props.row.displayTimezone }}
          </span>
          <span v-else>
            {{ props.row.timezone }}
          </span>
        </div>

        <!-- Column: cpm -->
        <span v-else-if="props.column.field === 'report.cpm'">
          <span v-if="props.row.report && props.row.report.cpm">
            ${{ amountFormat(props.row.report.cpm) }}
          </span>
          <span v-else>N/A</span>
        </span>

        <!-- Column: clicks -->
        <span v-else-if="props.column.field === 'report.clicks'">
          <span v-if="props.row.report && props.row.report.clicks">
            {{ localStringFormat(props.row.report.clicks) }}
          </span>
          <span v-else>N/A</span>
        </span>

        <!-- Column: impression -->
        <span v-else-if="props.column.field === 'report.impressions'">
          <span v-if="props.row.report && props.row.report.impressions">
            {{ localStringFormat(props.row.report.impressions) }}
          </span>
          <span v-else>N/A</span>
        </span>

        <!-- Column: impression -->
        <span v-else-if="props.column.field === 'report.ctr'">
          <span v-if="props.row.report && props.row.report.ctr">
            {{ localStringFormat(props.row.report.ctr) }}
          </span>
          <span v-else>N/A</span>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalAccount > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap p-1 font-14"
          :style="{
            'justify-content': $device.mobile ? 'end' : 'space-between',
          }"
        >
          <span
            class="d-none d-md-block text-secondary font-medium font-14"
          >{{ totalAccount }} {{ $t('home.tableListAdsAccount.textAvailableAccount') }}(s)</span>

          <!-- page length -->
          <div v-if="totalAccount > pageLength">
            <b-pagination
              :total-rows="totalAccount"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center"
      >
        <no-data-table-image class="my-5" />
      </div>
      <!-- / no account image -->
    </vue-good-table>
  </div>
</template>
<script>
import {
  BPagination,
  BSpinner,
  BImg,
  BLink,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import tableAdsAccountMixin from '@/mixins/tableAdsAccountMixin.vue'
import billingMixin from '@/mixins/billingMixin'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    NoDataTableImage,
    BPagination,
    BRow,
    BCol,
    BSpinner,
    BImg,
    BLink,
    VueGoodTable,
  },
  mixins: [tableAdsAccountMixin, billingMixin, envMixin],
  data() {
    return {
      rangeDate: {
        startDate: null,
        endDate: null,
      },
      columns: [
        {
          label: this.$t('account.ad'),
          field: '_id',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('account.timeZone'),
          field: 'timezone',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('home.tableListAdsAccount.costColumn'),
          field: 'report.cost',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'CPC',
          field: 'report.cpc',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'CPM',
          field: 'report.cpm',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Impression',
          field: 'report.impressions',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'Clicks',
          field: 'report.clicks',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: 'CTR (%)',
          field: 'report.ctr',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['isTikTokAppCenter']),
  },

  created() {
    this.fetchAdsAccounts()
  },

  methods: {
    handleAddNewAccount() {
      if (this.noPlan) {
        if (this.isTikTokAppCenter) {
          const idToken = localStorage.getItem('id_token')
          window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
        } else {
          this.$router.push('/billing')
        }
        return
      }

      this.$router.push('/my-ads-account?tab=settings')
    },
  },
}
</script>
<style lang="scss" scoped>
.bg-white {
  border-radius: var(--border-radius-lg);
}

.list-ads-account {
  padding: 20px;
}

.select-date{
  width: 50%;
}
.btn-new-account{
  width: max-content;
}
</style>
