<template>
  <b-row>
    <b-col cols="12">
      <div class="message d-flex align-items-center">
        <img
          class="voucher-img"
          style="margin-right: 5px"
          alt="alert"
          :src="require('@/assets/images/icons/ic-voucher-blue.png')"
        >
        <small>
          {{ $t('home.giftCodeNotification.contentNotification') }}
          <span
            class="text-blue cursor-pointer"
            @click="$router.push('/profile?tab=gift-code')"
          >
            {{ $t('home.giftCodeNotification.buttonCheckItOut') }}
          </span>
        </small>
      </div>
    </b-col>
  </b-row>
</template>
<script>

import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
}
</script>
<style lang="scss" scoped>
  .message {
    margin-top: 10px;
    background: rgba(38, 103, 255, 0.1);
    border-radius: 10px;
    padding: 15px 12px 15px 16px;
  }

  .voucher-img{
    width: 20px;
  }
</style>
